"use client";
import React, { useEffect, useState } from "react";
import wait from "waait";
import { cn } from "@/app/_utils/ui";

const AnimateIn = ({
  children,
  delay,
  className = "",
  animateFromClassname,
  animateToClassname,
}: {
  children: React.ReactNode;
  delay?: number;
  className?: string;
  animateFromClassname?: string;
  animateToClassname?: string;
}) => {
  const [animate, setAnimate] = useState(animateFromClassname);

  useEffect(() => {
    const animateIn = async () => {
      await wait(delay);
      setAnimate(animateToClassname);
    };

    animateIn();
  }, [delay, animateToClassname]);

  return (
    <div
      className={cn("transition-all ease-out duration-500", className, animate)}
    >
      {children}
    </div>
  );
};

export default AnimateIn;
