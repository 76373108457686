import * as React from "react";
import { useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { cn } from "@/app/_utils/ui";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, autoFocus, ...props }, ref) => {
    const internalRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => internalRef.current!);

    useEffect(() => {
      if (autoFocus && internalRef.current) {
        // Delay focusing to ensure the element is fully mounted
        const timer = setTimeout(() => {
          internalRef.current!.focus();
        }, 100);

        return () => clearTimeout(timer);
      }
    }, [autoFocus]);

    return (
      <input
        type={type}
        className={cn(
          "flex h-9 w-full rounded-sm border border-zinc-200 bg-white p-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-semibold placeholder:text-zinc-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-teal-tint disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        ref={internalRef}
        {...props}
      />
    );
  },
);

Input.displayName = "Input";

export { Input };
