import React, { useState, useEffect } from "react";
import { Input } from "../forms/input";
import { searchText } from "@/app/_utils/helper";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { stripLink } from "@/app/_utils/formatter";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../reducers/store";
import { searchArticle } from "../../reducers/analyticsSlice";
import ArticleRandom from "../search/ArticleRandom";

interface AutocompleteProps<T> {
  data: T[];
  valueKey: keyof T;
  onSelect: (item: T) => void;
  placeholder?: string;
  initialValue?: string;
  autoFocus?: boolean;
  onSelectRandom?: (articleId: string) => void;
  onClear?: (input: string) => void;
}

const ArticleSearchAutocomplete = <T extends { [key: string]: any }>({
  data,
  valueKey,
  onSelect,
  placeholder = "Search...",
  initialValue = "",
  autoFocus,
  onSelectRandom,
  onClear,
}: AutocompleteProps<T>) => {
  const [query, setQuery] = useState("");
  const [inputValue, setInputValue] = useState(initialValue);
  const [showOptions, setShowOptions] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useSelector(
    (state: RootState) => state.login.accessToken,
  );

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  const handleSelect = (item: T) => {
    onSelect(item);
    dispatch(
      searchArticle({
        accessToken,
        articleId: item.article.id,
        search: query,
      }),
    );
    setInputValue(item[valueKey]);
    setQuery("");
    setShowOptions(false);
  };

  const clearInput = () => {
    if (onClear) {
      onClear(inputValue);
    }
    setInputValue("");
    setQuery("");
    setShowOptions(false);
    dispatch(
      searchArticle({
        accessToken,
        articleId: "cancel",
        search: query,
      }),
    );
  };

  const filteredItems = data
    .map((item) => {
      const titleMatch = searchText(stripLink(item.title), query);
      const textMatch = searchText(item.text, query, true);
      return {
        ...item,
        titleMatch,
        textMatch,
      };
    })
    .filter((item) => item.titleMatch !== null || item.textMatch !== null)
    .sort((a, b) => {
      // Check vault status
      const aVault = a.article?.status?.vault ? 1 : 0;
      const bVault = b.article?.status?.vault ? 1 : 0;

      // Prioritize non-vault items
      if (aVault !== bVault) return aVault - bVault;

      // Prioritize title matches over text matches
      const aTitleMatch = a.titleMatch ? 1 : 0;
      const bTitleMatch = b.titleMatch ? 1 : 0;
      if (aTitleMatch !== bTitleMatch) return bTitleMatch - aTitleMatch;

      // For items with the same type of match (all title matches or all text matches), sort alphabetically
      return a.title.localeCompare(b.title);
    });

  const boldMatch = (text: string, match: string) => {
    if (!match) return <div>{text}</div>;

    const matchStart = text.toLowerCase().indexOf(match.toLowerCase());
    if (matchStart === -1) return <div>{text}</div>; // No match found

    const matchEnd = matchStart + match.length;
    const beforeMatch = text.substring(0, matchStart);
    const matchText = text.substring(matchStart, matchEnd);
    const afterMatch = text.substring(matchEnd);

    return (
      <div>
        {beforeMatch}
        <strong className="font-bold">{matchText}</strong>
        {afterMatch}
      </div>
    );
  };

  return (
    <div className="relative z-10 w-full">
      <div className="relative">
        <SearchOutlined className="absolute top-2 left-3" />
        <Input
          placeholder={placeholder}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            setQuery(e.target.value);
            setShowOptions(true);
          }}
          autoFocus={autoFocus}
          className="pl-10 pr-4"
        />
        <CloseOutlined
          className="absolute top-2 right-3 cursor-pointer scale-90 opacity-60"
          onClick={clearInput}
        />
      </div>

      {showOptions && (
        <div className="absolute w-full border -mt-px bg-white max-h-[40vh] overflow-auto top-9 rounded shadow-md z-30">
          {inputValue !== "" && filteredItems.length > 0 ? (
            filteredItems.map((item, index) => {
              const title = item.article?.status?.vault
                ? `Vault: ${stripLink(item[valueKey])}`
                : stripLink(item[valueKey]);
              return (
                <button
                  key={index}
                  className="px-4 py-2 text-base text-left cursor-pointer w-full hover:bg-neutral-200"
                  onClick={() => handleSelect(item)}
                >
                  <div className="line-clamp-1">{boldMatch(title, query)}</div>
                  {!item.titleMatch && item.textMatch && (
                    <div className="text-sm text-neutral-700 line-clamp-1">
                      {boldMatch(
                        stripLink(item.textMatch).split(" ").slice(1).join(" "),
                        query,
                      )}
                    </div>
                  )}
                </button>
              );
            })
          ) : (
            <>
              {inputValue !== "" && (
                <div className="px-4 py-2 text-base text-neutral-700">
                  No results found
                </div>
              )}
            </>
          )}
          {onSelectRandom && (
            <ArticleRandom
              className="sticky bottom-0 left-0"
              variant="dropdown"
              onSelectArticle={onSelectRandom}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ArticleSearchAutocomplete;
