import React, { useState, useEffect, useRef } from "react";
import { Input } from "../forms/input";
import { SearchOutlined, CloseOutlined, DownOutlined } from "@ant-design/icons";
import { useOnClickOutside } from "usehooks-ts";
import wait from "waait";

interface AutocompleteProps<T> {
  data: T[];
  valueKey: keyof T;
  onSelect: (item: T) => void;
  placeholder?: string;
  initialValue?: string;
  autoFocus?: boolean;
  additionalFilter?: (item: T) => boolean;
  icon?: React.ReactNode;
  isDropdown?: boolean;
  keepValueOnFocusChange?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SearchAutocomplete = <T extends { [key: string]: any }>({
  data,
  valueKey,
  onSelect,
  placeholder = "Search...",
  initialValue = "",
  autoFocus,
  additionalFilter,
  icon,
  isDropdown,
  keepValueOnFocusChange,
}: AutocompleteProps<T>) => {
  const [query, setQuery] = useState("");
  const [inputValue, setInputValue] = useState(initialValue);
  const [showOptions, setShowOptions] = useState(false);
  const [isFocused, setIsFocused] = useState(Boolean(autoFocus));
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useOnClickOutside(ref, () => {
    if (!keepValueOnFocusChange) {
      setInputValue(initialValue);
    }
    setShowOptions(false);
  });

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  const handleSelect = async (item: T) => {
    await onSelect(item);
    setInputValue(item[valueKey]);
    await wait(50);
    setQuery("");
    setShowOptions(false);
  };

  const clearInput = () => {
    setInputValue("");
    setQuery("");
    setShowOptions(false);
  };

  const filteredItems = data
    .filter((item) =>
      item[valueKey].toLowerCase().includes(query.toLowerCase()),
    )
    .filter((item) => !item.article?.status?.vault)
    .filter((item) => (additionalFilter ? additionalFilter(item) : true));

  return (
    <div ref={ref} className="relative z-10 w-full">
      <div className="relative">
        <div className="absolute pl-3 flex items-center top-0 left-0 h-full">
          {icon ? icon : <SearchOutlined />}
        </div>
        <Input
          ref={inputRef}
          placeholder={placeholder}
          value={inputValue}
          onFocus={() => {
            setInputValue("");
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          onChange={(e) => {
            setInputValue(e.target.value);
            setQuery(e.target.value);
            setShowOptions(true);
          }}
          autoFocus={autoFocus}
          className="pl-10 pr-4 text-base border-neutral-700 rounded h-10"
        />
        <div className="absolute pr-3 flex items-center top-0 right-0 h-full">
          {isDropdown ? (
            <>
              {isFocused ? (
                <CloseOutlined
                  className="cursor-pointer scale-90 opacity-60"
                  onClick={() => {
                    setInputValue(initialValue);
                    inputRef.current?.blur();
                    setShowOptions(false);
                  }}
                />
              ) : (
                <DownOutlined
                  className="cursor-pointer opacity-60"
                  onClick={() => {
                    inputRef.current?.focus();
                  }}
                />
              )}
            </>
          ) : (
            inputValue && (
              <CloseOutlined
                className="cursor-pointer scale-90 opacity-60"
                onClick={clearInput}
              />
            )
          )}
        </div>
      </div>

      {showOptions && (
        <div className="absolute w-full border -mt-px bg-white max-h-[280px] overflow-auto top-9 rounded shadow-md z-30">
          {filteredItems.length > 0 ? (
            filteredItems.map((item, index) => (
              <button
                key={index}
                className="p-2 text-base text-left cursor-pointer w-full hover:bg-neutral-200"
                onClick={async () => {
                  handleSelect(item);
                }}
              >
                {item[valueKey]}
              </button>
            ))
          ) : (
            <div className="p-2 text-base text-neutral-700">
              No results found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchAutocomplete;
